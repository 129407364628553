//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Column } from "@antv/g2plot";
export default {
  name: "SoftwareActiveRank",
  props: {
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    chartHeight: {
      type: String,
      default: "400px",
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    chartData() {
      var data = [];
      if (this.chartData && this.chartData.length > 0) {
        var stackActiveTimeData = this.chartData.map((m) => {
          return {
            SoftwareName: m.SoftwareName,
            Time: m.ActiveTime,
            TimeType: "活跃时长",
          };
        });
        var stackTotalTimeData = this.chartData.map((m) => {
          return {
            SoftwareName: m.SoftwareName,
            Time: m.TotalTime,
            TimeType: "总时长",
          };
        });
        data.push(...stackActiveTimeData);
        data.push(...stackTotalTimeData);
      }
      this.$nextTick(() => {
        this.chart.changeData(data);
      });
    },
  },
  methods: {
    renderChart() {
      if (this.chart) {
        return;
      }
      this.chart = new Column(this.$refs.chart, {
        data: [],
        isGroup: true,
        xField: "SoftwareName",
        yField: "Time",
        seriesField: "TimeType",
        colorField: "TimeType", // 部分图表使用 seriesField
        xAxis: {
          verticalLimitLength: 80,
          tickCount: 5,
          nice: true,
          label: {
            autoRotate: true,
            autoEllipsis: true,
          },
        },
        yAxis: {
          nice: true,
          label: {
            autoRotate: true,
            autoEllipsis: true,
            formatter: (text, item, index) => {
              return `${text}小时`;
            },
          },
        },
        color: ({ TimeType }) => {
          if (TimeType === "总时长") {
            return "#4F5DC0";
          }
          return "#F86532";
        },
        legend: {
          layout: "horizontal",
          position: "top",
        },
        label: false,
        // label: {
        //   // 可手动配置 label 数据标签位置
        //   position: "middle", // 'top', 'bottom', 'middle'、
        //   // 可配置附加的布局方法
        //   layout: [
        //     // 柱形图数据标签位置自动调整
        //     { type: "interval-adjust-position" },
        //     // 数据标签防遮挡
        //     { type: "interval-hide-overlap" },
        //     // 数据标签文颜色自动调整
        //     { type: "adjust-color" },
        //   ],
        // },
      });
      this.chart.update({
        theme: {
          maxColumnWidth: 20,
        },
      });
      this.chart.on("element:click", (e) => void this.$emit("elementClick", e));
      this.chart.render();
    },
  },
  mounted() {
    this.renderChart();
  },
};
